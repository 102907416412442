import React, { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const QRScanner = ({ onScan }) => {
  useEffect(() => {
    // Crea un'istanza di Html5QrcodeScanner
    const qrCodeScanner = new Html5QrcodeScanner(
      'reader', 
      { fps: 10, qrbox: 250 },
      false
    );

    // Avvia la scansione del QR
    qrCodeScanner.render(
      (decodedText) => {
        // Esegui la callback quando il QR è scansionato con successo
        onScan(decodedText);
      },
      (errorMessage) => {
        console.error("Errore durante la scansione:", errorMessage);
      }
    );

    // Cleanup quando il componente viene smontato
    return () => {
      qrCodeScanner.clear();
    };
  }, [onScan]);

  return <div id="reader" style={{ width: '300px', height: '300px' }} />;
};

export default QRScanner;
